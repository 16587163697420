export enum PriceLockupAlign {
  LEFT = "start",
  RIGHT = "end",
}

export interface PriceLockupProps {
  align?: PriceLockupAlign;
  a11yPrice: string;
  a11yStrikeThroughDialogTrigger?: string;
  className?: string;
  hasMoreInfoTrigger?: boolean;
  isStrikeThroughFirst?: boolean;
  lockupPrice?: string;
  onClickMoreInfoTrigger?: any;
  priceSubtextFirst?: string | string[];
  priceSubtextStandard?: string | string[];
  taxAndFeeClarify?: string;
  dateRange?: string;
  secondaryPrice?: string;
  strikeThroughPrice?: string;
  disclaimerRef?: React.RefObject<HTMLButtonElement>;
  buttonStyle?: React.CSSProperties | undefined;
  nightlyPrice?: string;
  formattedAmount?: string;
}
