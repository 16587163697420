import { useLocalization } from "@shared-ui/localization-context";
import { DATE_FORMAT } from "src/components/flexComponents/Hotels/utils/constants";
import { ExtendedHotel } from "src/services/adapters/BexApiPropertyAdapter";
import { PriceLockupProps } from "components/shared/PriceLockup/typings";

type PriceLockupTexts = Pick<PriceLockupProps, "priceSubtextFirst" | "priceSubtextStandard">;

export function useSubtextPriceLockup(hotel: ExtendedHotel): PriceLockupTexts {
  const { formatText, formatDateString } = useLocalization();

  const {
    isoCheckIn,
    isoCheckOut,
    hotelPrice: { messages },
  } = hotel;

  if (messages?.length) {
    return {
      priceSubtextFirst: messages[0]?.value,
      priceSubtextStandard: messages[1]?.value,
    };
  }

  const checkInDateFormatted = formatDateString(isoCheckIn, { skeleton: DATE_FORMAT });
  const checkOutDateFormatted = formatDateString(isoCheckOut, { skeleton: DATE_FORMAT });

  return {
    priceSubtextFirst: formatText("hotels.price.perNight"),
    priceSubtextStandard: formatText("hotels.whenBooking", checkInDateFormatted, checkOutDateFormatted),
  };
}

export default useSubtextPriceLockup;
