import * as React from "react";
import { PriceA11y } from "src/components/flexComponents/Hotels/utils/A11y/priceA11yInterface";
import { Hotel } from "src/typings/microserviceModels/hotels-flex-module";
import { PriceLockupAlign } from "components/shared/PriceLockup/typings";
import { PriceLockup } from "components/shared/PriceLockup/PriceLockup";
import { useSubtextPriceLockup } from "./useSubtextPriceLockup";

interface PriceLockupDefaultProps {
  hotel: Hotel;
  align?: PriceLockupAlign;
  priceA11y?: PriceA11y;
  strikeThroughPrice?: string;
}

export const PriceLockupDefault = ({ hotel, priceA11y, strikeThroughPrice, align }: PriceLockupDefaultProps) => {
  const { formattedPrice, hotelPrice } = hotel;
  const { priceSubtextFirst, priceSubtextStandard } = useSubtextPriceLockup(hotel);
  const { formattedAmount, dateRange, secondaryPrice, taxAndFeeClarify, nightlyPrice } = hotelPrice;

  const lockupPrice = formattedAmount ?? formattedPrice;

  const props = {
    lockupPrice,
    priceSubtextFirst,
    priceSubtextStandard,
    a11yPrice: priceA11y?.getA11yText() || "",
    strikeThroughPrice,
    align,
    dateRange,
    taxAndFeeClarify,
    secondaryPrice,
    nightlyPrice,
    formattedAmount,
  };

  return <PriceLockup {...props} />;
};

export default PriceLockupDefault;
